import React from 'react'
import { FirestoreCollection } from 'react-firestore'
import styled from 'styled-components/macro'

import {
  AppLink,
} from 'styles/buttons'

const UserWelcomeGuideItem = ({
  guide,
}) => {

  return <FirestoreCollection path={`/guides/${guide.id}/drafts`}>
    {({ isLoading, error, data }) => {
      if (isLoading) {
        return '...'
      }
      if (error) {
        return 'error'
      }

      let draft = data[0]

      return <ItemWrapper>
        <div>
          <h3>
            {draft?.title || 'Untitled guide'}
            { !guide.is_live && <DraftBadge>Draft</DraftBadge> }
            { !guide.is_coming_soon && <DraftBadge>Coming Soon</DraftBadge> }
          </h3>
        </div>
        <div>
          {guide.is_live ? 
            <AppLink to={`/g/${guide.id}`}>View</AppLink>
          :
            <AppLink to={`/g/${guide.id}?preview_draft=${draft.id}`}>Preview</AppLink>
          }
          {' · '}
          <AppLink to={`/g/${guide.id}/edit`}>Edit</AppLink>
        </div>
      </ItemWrapper>

    }}
  </FirestoreCollection>
}

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
  align-items: center;

  border-bottom: 1px solid var(--hairline);
  padding: 1rem 0;

  h3 {
    margin: 0;
    font-weight: 500;
  }

  @media(max-width: 599px) {
    grid-template-columns: auto;
    grid-row-gap: 1rem;
  }
`
const DraftBadge = styled.span`
  font-size: var(--xs);
  font-weight: 500;
  margin: 0 0 0 .5rem;
  padding: 3px 10px;
  display: inline-block;
  color: var(--text-secondary);
  background: var(--background-item);
  border-radius: 100px;
`

export default UserWelcomeGuideItem
