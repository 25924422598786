import React, { useEffect, useState } from 'react'
import Firebase from 'firebase/app'
import { useDebouncedCallback } from 'use-debounce'
import { useDocument } from 'react-firebase-hooks/firestore'
import styled from 'styled-components/macro'

import updateGuideDraft from 'actions/updateGuideDraft'
import LogError from 'actions/LogError'
import ErrorPage from 'views/_components/ErrorPage'
import NormalLayout from 'views/_layouts/NormalLayout'
import GuideEditNav from './GuideEditNav'
import PublishMenu from './PublishMenu'
import GuideEditWelcomeModal from './GuideEditWelcomeModal'
import BasicInfo from './BasicInfo'
import EditorWrapper from 'views/guide_edit/EditorWrapper'

// When I first built this, I just sent all keystrokes to Firebase immediately
// I think Firebase has built-in batching, so it kind of worked fine...
// Until it didn't and I got a bunch of "too many saves"-type errors

// So we need to debounce the updates to Firebase a little
// That's what this component is for--it saves a local copy of the whole draft in state
// so that all the forms/components below can update/change/edit the same copy, immediately
// And every once it a while (1-2 seconds max) it updates Firebase

// Anything that's a child of this component should use its version of the localDraft to render
// and should use the updateDraft method here. It will also have its edit saves debounced.
// If you try to use the Firebase draft directly, updates will be out of sync with renders and you'll lose data.
// For ex, I used to have only the BasicInfo and Editor debouncing, and when I deleted a section from the nav,
// the whole section came back (because it was saved in localDraft, which got re-updated when I typed something)

// It could cause problems that currentSection lives in this component too?
// (That's why I originally moved this to a lower level)
// But I don't think it'll be an issue since there's a key on the EditorWrapper
// and EditorWrapper contains all the logic for generating the editor content (which used to live here)

const GuideEdit = ({
  guide,
  draft,
  user,
}) => {

  const [localDraft, setLocalDraft] = useState(draft)
  const [currentSection, setCurrentSection] = useState('basic')

  // function to update local draft
  const updateDraft = newDraft => {
    console.log('    localDraft updated')
    setLocalDraft(newDraft)
    saveDraftDebounced()
  }
  
  // function to save local draft to firestore
  const saveDraft = () => {
    console.log('Saving draft to firestore...')  
    updateGuideDraft(draft.ref, localDraft)
      .catch( error => {
        LogError('save this draft', error, true)
      })
  }
  
  // debounced version of Firestore save
  // https://github.com/xnimorz/use-debounce
  const saveDraftDebounced = useDebouncedCallback(saveDraft, 1000, { maxWait: 2000 })

  // save any pending changes before unmount only
  useEffect( () => {
    return () => saveDraftDebounced.flush()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // monitor save status
  const [draftSnapshot] = useDocument(
    Firebase.firestore().doc(`/guides/${guide.id}/drafts/${draft.id}`),
    { snapshotListenOptions: { includeMetadataChanges: true }, }
  )
  const waitingForServerSave = draftSnapshot?.metadata?.hasPendingWrites


  // RENDER

  if (!localDraft) {
    return <ErrorPage error={new Error('no draft for this guide')} />
  }

  return <NormalLayout hideCTAs title={`Editing ${localDraft.title}`}>
  
    {(!user || !user.hasClosedEditorWelcomeModal) && <GuideEditWelcomeModal user={user} /> }

    <GuideEditColumns>
      <div>
        <GuideEditNav
          localDraft={localDraft}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          updateDraft={updateDraft}
        />
      </div>

      <div>
        { currentSection === 'basic' ?
          <BasicInfo
            localDraft={localDraft}
            updateDraft={updateDraft}
          />
          :
          <EditorWrapper
            key={currentSection} /* ensure re-render on currentSelection change */
            localDraft={localDraft}
            currentSection={currentSection}
            updateDraft={updateDraft}
          />
        }
      </div>

      <div>
        <PublishMenu
          guide={guide}
          localDraft={localDraft}
          waitingForDebounce={saveDraftDebounced.isPending()}
          waitingForServerSave={waitingForServerSave}
        />
      </div>

    </GuideEditColumns>

  </NormalLayout>
}

const GuideEditColumns = styled.div`
  display: grid;
  grid-template-columns: 14rem auto 10rem;
  grid-column-gap: 3rem;

  margin: 0 auto;

  @media(max-width: 999px) {
    grid-template-columns: 14rem auto;
    grid-row-gap: 2rem;
  }
  @media(max-width: 799px) {
    grid-template-columns: auto;
    grid-row-gap: 2rem;
  }
`

export default GuideEdit
