import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import ErrorPage from 'views/_components/ErrorPage'
import NormalLayout from 'views/_layouts/NormalLayout'
import showDayFromTimestamp from 'views/_util/showDayFromTimestamp'
import GuideNav from 'views/guide/GuideNav'
import GuideMenu from 'views/guide/GuideMenu'
import GuideSection from 'views/guide/GuideSection'
import BubbleHeadItem from 'views/_components/BubbleHeadItem'
import { Well } from 'styles/misc'
import GUIDE_COLORS from 'data/GuideColors'
import LICENSE_OPTIONS from 'data/LicenseOptions'
import SaveButton from './SaveButton'
import GuideSaves from './GuideSaves'

const Guide = ({
  guide,
  version,
  isDraft,
  author,
  auth,
}) => {
    
  const [currentSection, setCurrentSection] = useState(0)

  const goToSection = index => {
    setCurrentSection(index)
    const element = document.getElementById(`#section-header-${index}`)

    // let the guide render and the page reflow first
    setTimeout( () => element.scrollIntoView({ behavior: "smooth" }), 100)
  }

  const lastUpdateTime = guide.updatedOn ? `updated ${showDayFromTimestamp(guide.updatedOn)}` :
                         guide.createdOn ? `created ${showDayFromTimestamp(guide.createdOn)}` :
                         ''
  
  if (!isDraft && !guide.is_live) {
    return <NormalLayout>
      <h2>This guide has either been removed, or is not yet published</h2>
      <p>Please check back soon!</p>
    </NormalLayout>
  }

  if (!version) {
    return <ErrorPage error={new Error('no version for this guide')} />
  }

  const license = LICENSE_OPTIONS.find(lo => lo.id === version.license)

  return <NormalLayout
    hideCTAs
    title={version.title || 'Unnamed guide'}
    navColor={version.color ? GUIDE_COLORS.find(gc => gc.id === version.color)?.color || 'var(--brand-primary)' : 'var(--brand-primary)'}
  >

    <GuideHeader color={version.color}>
      <GuideColumns>
        <GuideLeft>
        </GuideLeft>
        <GuideMiddle>

          <GuideWrapper>
            <GuideTitle>{version.title}</GuideTitle>
            <GuideSubtitle>{version.subtitle}</GuideSubtitle>
            <GuideByline>
              <a href="#author">{author.displayName}</a>
            </GuideByline>
          </GuideWrapper>

        </GuideMiddle>
        <GuideRight>
        </GuideRight>
      </GuideColumns>
    </GuideHeader>

    <GuideColumns>
      <GuideLeft>
        {!version.is_coming_soon &&
          <GuideNav
            version={version}
            goToSection={goToSection}
            currentSection={currentSection}
          />
        }
      </GuideLeft>
      <GuideMiddle>

        <GuideWrapper>
          
          {version.is_coming_soon ?
            <ComingSoonPromo>
              <h3>COMING SOON</h3>
              <p>This guide hasn't been released yet. Add to your library to get notified when it comes out.</p>
              <SaveButton guide={guide} />
            </ComingSoonPromo>
            :
            <>
              <GuideActionBar>
                <SaveButtonWrapper>
                  <SaveButton secondary guide={guide} />
                  <GuideSaves guide={guide} />
                </SaveButtonWrapper>
                <GuideMenu guide={guide} />
              </GuideActionBar>

              <GuideMetadata>
                <GuidePrice>Free</GuidePrice>
                {' · '}
                {version.sections ? version.sections.length : 0} sections
                {' · '}
                {version.topic || 'General knowledge'}
                {' · '}
                {lastUpdateTime}
              </GuideMetadata>
              
              {isDraft &&
                <Well>
                  This is a draft of an unpublished guide
                </Well>
              }


              { version.intro &&
                <GuideIntro>
                  {version.intro}
                </GuideIntro>
              }

              { version.ideal_audience &&
                <GuideIdealAudience>
                  <span>Best for</span>
                  {' '}
                  {version.ideal_audience}
                </GuideIdealAudience>
              }

              { version.sections && version.sections.map( (section, index) => {
                return <SectionWrapper key={index}>
                  <SectionHeading
                    id={`#section-header-${index}`}
                    onClick={() => goToSection(index)}
                  >
                    <SectionHeadingEyebrow>Section {index + 1}</SectionHeadingEyebrow>
                    <SectionHeadingMain>{section.title}</SectionHeadingMain>
                  </SectionHeading>
                  {currentSection === index && <GuideSection section={section} auth={auth} /> }
                </SectionWrapper>
              })}

              <SectionWrapper key={'appendix'}>
                <SectionHeading
                  id={`#section-header-appendix`}
                  onClick={() => goToSection('appendix')}
                >
                  <SectionHeadingMain small>Appendix</SectionHeadingMain>
                </SectionHeading>
                {currentSection === 'appendix' &&
                  <>
                    <h3>Acknowledgments</h3>
                    {version.acknowledgments}
                    <br />
                    <br />
                  </>
                }
              </SectionWrapper>

              <hr />

              <GuideLicense href={license.url || null} target="_blank">
                <svg style={{ width:'24px', height:'24px' }} viewBox="0 0 24 24">
                  <path fill="currentColor" d="M11.89,10.34L10.55,11.04C10.41,10.74 10.24,10.53 10.03,10.41C9.82,10.29 9.62,10.23 9.45,10.23C8.55,10.23 8.11,10.82 8.11,12C8.11,12.54 8.22,12.97 8.45,13.29C8.67,13.61 9,13.77 9.45,13.77C10.03,13.77 10.44,13.5 10.68,12.91L11.91,13.54C11.65,14.03 11.29,14.41 10.82,14.69C10.36,14.97 9.85,15.11 9.29,15.11C8.39,15.11 7.67,14.84 7.12,14.29C6.58,13.74 6.3,13 6.3,12C6.3,11.05 6.58,10.3 7.13,9.74C7.69,9.18 8.39,8.9 9.23,8.9C10.47,8.89 11.36,9.38 11.89,10.34M17.66,10.34L16.34,11.04C16.2,10.74 16,10.53 15.81,10.41C15.6,10.29 15.4,10.23 15.21,10.23C14.32,10.23 13.87,10.82 13.87,12C13.87,12.54 14,12.97 14.21,13.29C14.44,13.61 14.77,13.77 15.21,13.77C15.8,13.77 16.21,13.5 16.45,12.91L17.7,13.54C17.42,14.03 17.05,14.41 16.59,14.69C16.12,14.97 15.62,15.11 15.07,15.11C14.17,15.11 13.44,14.84 12.9,14.29C12.36,13.74 12.09,13 12.09,12C12.09,11.05 12.37,10.3 12.92,9.74C13.47,9.18 14.17,8.9 15,8.9C16.26,8.89 17.14,9.38 17.66,10.34M12,3.5A8.5,8.5 0 0,1 20.5,12A8.5,8.5 0 0,1 12,20.5A8.5,8.5 0 0,1 3.5,12A8.5,8.5 0 0,1 12,3.5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <div>
                  <LicenseName>
                    { license?.name || 'Unknown license'}
                  </LicenseName>
                  <LicenseDescription>
                    { license?.description || 'No license description given. Contact author for licensing questions.' }
                  </LicenseDescription>
                  {license?.url && <LicenseLink>Full license</LicenseLink> }
                </div>
              </GuideLicense>
            </>

          } {/* end !is_coming_soon */}

          <AuthorBio id="author" to={`/u/${guide.createdBy}`}>
            <div style={{filter: 'grayscale(0%)'}}>
              <BubbleHeadItem size={64} userId={guide.createdBy} />
            </div>
            <div>
              <AuthorBioName>{author.displayName}</AuthorBioName>
              {' '}
              {version.author_bio}
            </div>
          </AuthorBio>

          <br />
          <br />
          <br />
          <br />

        </GuideWrapper>
      </GuideMiddle>
      <GuideRight>

      </GuideRight>
    </GuideColumns>

  </NormalLayout>
}

// Layout

const GuideColumns = styled.div`
  display: grid;
  grid-template-columns: 8rem auto 8rem;
  grid-column-gap: 2rem;
  
  @media(max-width: 1199px) {
    grid-template-columns: 8rem auto 0;
    grid-column-gap: 2rem;
  }
  @media(max-width: 699px) {
    grid-template-columns: auto;
    grid-column-gap: 0;
  }
`
const GuideLeft = styled.div`
  padding-top: 2rem;

  @media(max-width: 699px) {
    display: none;
  }
  `
const GuideMiddle = styled.div`
`
const GuideRight = styled.div`
  @media(max-width: 699px) {
    display: none;
  }
`
const GuideWrapper = styled.div`
  margin: 0 auto;
  max-width: 40rem;
`

// Header

const GuideHeader = styled.div`
  color:      ${p => p.color ? GUIDE_COLORS.find(gc => gc.id === p.color)?.color      || 'var(--brand-primary)' : 'var(--brand-primary)'};
  background: ${p => p.color ? GUIDE_COLORS.find(gc => gc.id === p.color)?.background || 'var(--background-item)' : 'var(--background-item)'};
  box-shadow: ${p => p.color ? `0 -1000px 0 1000px ${GUIDE_COLORS.find(gc => gc.id === p.color)?.background}` || '0 -1000px 0 1000px var(--background-item)' : '0 -1000px 0 1000px var(--background-item)'};
`
const GuideTitle = styled.h1`
  font-family: var(--font-family-display);
  color: inherit;
  letter-spacing: -0.02em;
  line-height: .8;
  font-weight: 400;

  font-size: 4rem;
  margin: 10vh 0 2.75rem;
`
const GuideSubtitle = styled.div`
  font-weight: 600;
  margin: -2.5rem 0 1.5rem;
  font-size: var(--l);
  opacity: .8;
  `
const GuideByline = styled.div`
  font-size: var(--s);
  font-weight: 400;
  margin: 0 0 1.5rem;
  opacity: .8;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

// Action bar etc

const GuideActionBar = styled.div`
  /* border-top: 1px solid var(--hairline); */
  margin: 2rem 0 1rem;

  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
  align-items: center;
`
const SaveButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-column-gap: 1rem;
`
const GuideMetadata = styled.div`
  font-size: var(--s);
  margin: 0 0 2rem;
`
const GuidePrice = styled.span`
  font-weight: 500;
  color: var(--green-60);
`

// intro

const ComingSoonPromo = styled.div`
  background: var(--background-item);
  padding: 1.5rem;
  margin: 2rem 0 4rem;

  h3 {
    margin: 0 0 .5rem;
  }
`
const GuideIntro = styled.div`
  padding: 0 0 2rem;
  line-height: 1.6;
  font-size: var(--l);
  color: var(--text-primary);
  white-space: pre-line;
`
const GuideIdealAudience = styled.div`
  padding: 0 0 2rem;
  line-height: 1.6;
  font-size: var(--s);
  color: var(--text-secondary);
  white-space: pre-line;
  
  span {
    color: var(--text-primary);
    font-weight: 500;
  }
`

// bio

const AuthorBio = styled(NavLink)`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 1rem;

  font-size: var(--s);
  color: var(--text-secondary);
  padding: 0 0 2rem;

  @media(max-width: 699px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
`
const AuthorBioName = styled.span`
  font-weight: 500;
  display: inline;
  color: var(--text-primary);
`

// sections

const SectionWrapper = styled.div`
  border-top: 1px solid var(--hairline);
`
const SectionHeading = styled.div`
  cursor: pointer;
  padding: 1.5rem 0;
  `
const SectionHeadingEyebrow = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: var(--xs);
  color: var(--text-secondary);
  margin: 0 0 .25rem;
  `
const SectionHeadingMain = styled.h2`
  font-weight: 900;
  font-size: ${p => p.small ? 'var(--l)' : '34px'};
  color: var(--text-primary);
  margin: 0;
`

// license

const GuideLicense = styled.a`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 1rem;
  margin: 2rem 0 4rem;
  `
const LicenseName = styled.div`
  font-size: var(--s);
  font-weight: 500;
  color: var(--text-primary);
  margin: 0 0 .25rem;
  `
const LicenseDescription = styled.div`
  font-size: var(--s);
  color: var(--text-secondary);
  margin: 0 0 .25rem;
`
const LicenseLink = styled.div`
  font-size: var(--s);
  color: var(--link);
  &:hover {
    text-decoration: underline;
  }
`

export default Guide
