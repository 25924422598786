import React from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import styled from 'styled-components/macro'
import { FirestoreDocument } from 'react-firestore'

import { RESOURCE_LINK_HTML_TAG } from './EditorConstants'

const ResourceLinkComponent = ({
  node,
  editor,
}) => {

  const isEditable = editor.options.editable

  const { url, resourceId } = node.attrs

  return <FirestoreDocument path={`resources/${resourceId}`}>
    {({isLoading, error, data}) => {

      if (isLoading) {
        return 'Loading...'
      }

      if (error) {
        return 'Error getting resource'
      }
      
      console.log(data.metadata)
          
      const { title, description, thumbnailUrl } = data.metadata

      const content = <ResourceLinkContent>
        <ResourceLinkDisplayThumb thumbnailUrl={thumbnailUrl} />
        <ResourceLinkDisplayInfo>
          <h4>{title}</h4>
          <div>{description}</div>
          <div>{url}</div>
        </ResourceLinkDisplayInfo>
      </ResourceLinkContent>

      return <NodeViewWrapper className={RESOURCE_LINK_HTML_TAG}>
        { isEditable ?
          <ResourceLinkEditorWrapper data-drag-handle>
            {content}
          </ResourceLinkEditorWrapper>
        :
          <ResourceLinkDisplayWrapper href={url} target="_blank">
            {content}
          </ResourceLinkDisplayWrapper>
        }
      </NodeViewWrapper>
    }}
  </FirestoreDocument>

}


// wrappers
const ResourceLinkEditorWrapper = styled.div`
  display: block;
  margin: 0;
  
  background: var(--background-item);
  border-radius: 8px;
  overflow: hidden;

  cursor: move;
`
const ResourceLinkDisplayWrapper = styled.a`
  display: block;
  margin: 0;
  
  border: 1px solid var(--hairline-dark);
  box-shadow: 0 4px 4px var(--shadow);
  border-radius: 8px;
  overflow: hidden;

  cursor: pointer;
  
  &:hover {
    border-color: var(--hairline-darker);
  }
`

// content

const ResourceLinkContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 1rem;

  color: var(--text-primary);
  
  @media(max-width: 599px) {
    grid-template-columns: auto;
  }
`
const ResourceLinkDisplayThumb = styled.div`
  background: #eee;
  background-image: ${p => `url(${p.thumbnailUrl})`};
  background-size: cover;
`
const ResourceLinkDisplayInfo = styled.div`
  padding: 1rem;

  h4 {
    margin: 0 0 .5rem;
  }
`

export default ResourceLinkComponent
